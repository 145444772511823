import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import BASE_URL from "../../services/index.js";
import { Spinner } from "./components/Spinner.jsx";
import Cookies from "js-cookie";
import Joyride, { STATUS } from "react-joyride";

import Layout from "./layout.jsx";
import profilepic from "../../assets/images/profilepic.svg";
import locationicon from "../../assets/images/location icon.svg";
import partner from "../../assets/images/partner icon.svg";
import dislike from "../../assets/images/Dislike button.svg";
import rewindd from "../../assets/images/rewind.svg";
import like from "../../assets/images/Like button.svg";
import profile from "../../assets/images/person.svg";
import MobileHeader from "./components/MobileHeader.jsx";
import RightSidebar from "./components/RightSidebar.jsx";
import { SkeletonLoader } from "./components/SkeletonLoader.jsx";
import close from "../../assets/images/close-modal.svg";
import CountdownTimer from "./components/CountdownTimer.jsx";
import premium from "../../assets/images/premium.svg";
import { ReminderModal } from "./components/ReminderModal.jsx";
import useUser from "../../hooks/useUser.jsx";
import useFeedback from "../../hooks/useFeedback";
import { FeedbackButton } from "./components/RightSidebar.jsx";
import ex from "../../assets/images/ex.svg";
import left from "../../assets/images/left.svg";
import right from "../../assets/images/right.svg";
import successTick from "../../assets/images/success-tick.svg";
import oops from "../../assets/images/oops.svg";
import avatar from "../../assets/images/avatar.png";

const DashboardLanding = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [userData, setUserData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [noMoreUsers, setNoMoreUsers] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [countdownTime, setCountdownTime] = useState(43200); //* 12 hours in seconds
  const [showCard, setShowCard] = useState(false);
  const [logicError, setLogicError] = useState(null);
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);
  const { user } = useUser();
  const [pageNumber, setPageNumber] = useState();
  const [rewinddd, setRewinddd] = useState(null);
  const [isRewindOpen, setIsRewindOpen] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentContent, setCurrentContent] = useState(1);
  const [isContentCompleted, setIsContentCompleted] = useState(false);
  const { feedback } = useFeedback();
  const [showButtons, setShowButtons] = useState(true);
  const [userDataIndex, setUserDataIndex] = useState(0);
  const [runTour, setRunTour] = useState(false);

  const steps = [
    {
      target: ".dashboard-header",
      content:
        "Welcome to HelpAI, where AI meets professional matchmaking! Simply like someone, and when it's mutual, it's a match!",
      // disableBeacon: true,
    },
    {
      target: ".action-buttons",
      content:
      "Use these buttons to interact with the profile. You can like, dislike, rewind or view more details about a profile.",
    },
    {
      target: ".rewind-button",
      content: "The Rewind button takes you back to the profile you might have skipped earlier, giving you a second chance to connect.",
      // disableBeacon: true,
    },
    {
      target: ".dislike-button",
      content: "If you come across a profile that doesn't align with your vision, Hit the Dislike button to move on and keep exploring",
    },
    {
      target: ".like-button",
      content: "Show interest and earmark a profile for future connections by tapping Like button. This adds the profile to your potential matches.",
    },
    {
      target: ".profile-button",
      content: "Explore more about a user by tapping on View Profile button. This allows you to delve into comprehensive details and make informed decisions",
    },
    {
      target: ".upgrade-button",
      content: "Upgrade your account for more features!",
    },
    // {
    //   target: ".feedback-button",
    //   content: "Don't forget to give us feedback on your experience!",
    // },
    {
      target: ".user-card",
      content:
        "Congratulations! 🎉 Get ready to dive into the world of professional connections and discover endless possibilities. Happy matching! 🚀",
    },
  ];

  useEffect(() => {
    //* Start the tour when the component mounts
    setRunTour(true);
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      //* Need to set our running state to false, so we can restart if we click start again.
      setRunTour(false);
    }
  };

  useEffect(() => {
    const fetchCookie = async () => {
      try {
        // Get the cookie value directly using js-cookie
        const cookieValue = Cookies.get("hai_auth", { domain: ".helpai.co" });

        // console.log("Cookie Value:", cookieValue);

        if (cookieValue) {
          // Parse the JSON string to get individual values
          const cookieObject = JSON.parse(cookieValue);
          const { email, idToken: accessToken } = cookieObject;

          // console.log("Email:", email);
          // console.log("Access Token:", accessToken);

          // Set cookies using js-cookie
          Cookies.set("userEmail", email);
          Cookies.set("accessToken", accessToken);
          localStorage.setItem("userEmail", email);
          localStorage.setItem("accessToken", accessToken);
        }
      } catch (error) {
        console.error("Error parsing cookie value:", error);
      }
    };

    fetchCookie();
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentContent(1);
    setIsContentCompleted(false);

    const feedbackData = {
      rating: feedback.selectedValue.toString(),
      reason: feedback.reason,
      product_quality: feedback.product,
      customer_support: feedback.customer,
      tips: feedback.advise,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    };

    axios
      .post(BASE_URL + "/account/create-feedback", feedbackData, { headers })
      .then((response) => {
        console.log("Feedback successfully submitted:", response.data);
      })
      .catch((error) => {
        console.error("Error submitting feedback:", error);
      });
  };

  useEffect(() => {
    setShowButtons(currentContent !== 6);
  }, [currentContent]);

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(BASE_URL + `/account/discover`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });

      const data = response.data;
      // console.log(data); dashboard data here
      setUserData(data.data[0]);
      setIsLoading(false);
    } catch (error) {
      // console.error('Error fetching data:', error);
      setLogicError(error.response?.data?.Message || "An error occurred");
      setTimeout(() => {
        setLogicError("");
      }, 8000);
      console.log(error.response?.data?.Message || "An error occurred");
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchPageData = async () => {
    try {
      const response = await axios.get(
        BASE_URL + `/account/discover?next=${pageNumber}`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      const data = response.data;
      const next = data?.pagination?.nextPage;
      // console.log(next);
      setPageNumber(next);
      setUserData(data.data[0]);
      // console.log(data);
      setTotalPages(data.pagination.userCount);
      //console.log(data.pagination.nextPage);

      if (data.pagination.nextPage === null) {
        setNoMoreUsers(false);
      }

      setTimeout(() => {
        setNoMoreUsers("");
      }, 7000);
    } catch (error) {
      // console.error("Error fetching data:", error);
      setLogicError(error?.response?.data?.message);
      setTimeout(() => {
        setLogicError("");
      }, 8000);
    }
  };

  useEffect(() => {
    fetchPageData();
  }, [currentPage]);

  const handleLikeClick = async () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }

    if (userData) {
      try {
        const response = await axios.post(
          BASE_URL + `/account/like/${userData._id}`,
          {},
          { headers: { Authorization: `Bearer ${user.token}` } }
        );
        // console.log('Liked user:', response.data);
      } catch (error) {
        // console.log('Error liking user:', error?.response?.data?.Message);
        // console.log(error.response.data.Message);
        if (
          error?.response?.data?.Message ===
          "You've exhausted your free likes for the first 12-hour period and currently in cool down mode."
        ) {
          setError("Too Many Requests. Upgrade to premium");
          setShowCard(true);
        }
      }
    }
    fetchData();
  };

  const handleDislikeClick = async () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 2);
    }

    if (userData) {
      try {
        const response = await axios.post(
          BASE_URL + `/account/dislike/${userData._id}`,
          {},
          { headers: { Authorization: `Bearer ${user.token}` } }
        );
        // console.log('Disliked user:', response.data);
      } catch (error) {
        // console.log('Error disliking user:', error?.response?.data?.Message);
        // console.log(error.response.data.Message);
        if (
          error.response.data.Message ===
          "You've exhausted your free likes for the first 12-hour period and currently in cool down mode."
        ) {
          setError("Too Many Requests. Upgrade to premium");
          setShowCard(true);
        }
      }
    }
    fetchData();
  };

  const handleProfileClick = async (userId) => {
    setIsModalOpen(true);
    try {
      const response = await axios.get(
        BASE_URL + `/account/discover/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setModalData(response.data.data);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setLogicError(error.response.data.Message);
      setTimeout(() => {
        setLogicError("");
      }, 8000);
    }
  };

  const handleRewindProfile = async () => {
    try {
      const response = await axios.get(BASE_URL + "/account/rewind-profile", {
        headers: { Authorization: `Bearer ${user.token}` },
      });

      // Check if there are more users to display
      if (userDataIndex + 1 < response.data.length) {
        setUserDataIndex((prevIndex) => prevIndex + 1);
        setUserData(response.data[userDataIndex + 1]);
      } else {
        setUserDataIndex(0);
        setUserData(response.data[0]);
      }
      // console.log(response.data);
    } catch (error) {
      console.error("Error rewindind profile:", error);
      setIsRewindOpen(true);
    }
  };

  const closeRewindModal = () => {
    setIsRewindOpen(false);
  };

  return (
    <div className="">
      <Joyride
        steps={steps}
        run={runTour}
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            arrowColor: "#414080",
            backgroundColor: "#414080",
            primaryColor: "#f5a623",
            textColor: "#FFFFFF",
            overlayColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      />
      {isTabletOrMobile ? (
        <div className="bg-[#FAFBFD] px-2 py-14 h-screen">
          <MobileHeader />
          <>
            <div className="mb-20">
              <h1 className="changa-bold text-xl mb-[2px] text-[#454488] dashboard-header">
                Dashboard
              </h1>
              <p className="font-normal text-base text-[#454488]">
                Find your dream co-founder
              </p>
            </div>
            <div className="flex flex-col justify-center items-center">
              {isLoading && <SkeletonLoader />}
              {userData && (
                <div>
                  {showCard ? null : (
                    <Card
                      fullName={userData.fullName}
                      userLocation={userData.userLocation}
                      skillDemanded={userData.skillDemanded}
                      elevatorPitch={userData.elevatorPitch}
                      profileImage={userData.profileImage}
                      rewindClick={handleRewindProfile}
                      onLikeClick={handleLikeClick}
                      onDislikeClick={handleDislikeClick}
                      onClickProfile={() => handleProfileClick(userData._id)}
                      modalData={modalData}
                      isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen}
                    />
                  )}
                </div>
              )}
              {!userData && (
                <div className="3xl:pl-[170px]">
                  <OutOfUsers />
                </div>
              )}
              {showCard && <CountdownTimer countdownTime={countdownTime} />}
            </div>
          </>
          <div className="fixed -right-[70px] top-[50%] ">
            <button
              onClick={toggleModal}
              className="bg-[#414080] px-9 py-2 text-white changa rounded-lg cursor-pointer -rotate-90 feedback-button"
            >
              Give Feedback
            </button>
          </div>

          {showModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-5 rounded-lg w-screen md:w-[460px]">
                <div className="flex justify-end items-center">
                  <div className="cursor-pointer" onClick={toggleModal}>
                    <img src={ex} alt="" />
                  </div>
                </div>
                <>
                  {currentContent === 1 && (
                    <Content1 handleCompletion={setIsContentCompleted} />
                  )}
                  {currentContent === 2 && (
                    <Content2 handleCompletion={setIsContentCompleted} />
                  )}
                  {currentContent === 3 && (
                    <Content3 handleCompletion={setIsContentCompleted} />
                  )}
                  {currentContent === 4 && (
                    <Content4 handleCompletion={setIsContentCompleted} />
                  )}
                  {currentContent === 5 && (
                    <Content5 handleCompletion={setIsContentCompleted} />
                  )}
                  {currentContent === 6 && <Content6 closeModal={closeModal} />}
                </>
                <div className="">
                  {showButtons && (
                    <div className="flex justify-between items-center mb-5">
                      <button
                        onClick={() => setCurrentContent(currentContent - 1)}
                        className={`bg-[#414080] px-6 py-2 text-white rounded-lg cursor-pointer ${
                          currentContent === 1
                            ? "cursor-not-allowed opacity-50"
                            : ""
                        }`}
                        disabled={currentContent === 1}
                      >
                        <img src={left} alt="previous" />
                      </button>
                      <button
                        disabled={!isContentCompleted}
                        onClick={() => setCurrentContent(currentContent + 1)}
                        className={`bg-[#414080] px-6 py-2 text-white rounded-lg  ${
                          !isContentCompleted
                            ? "cursor-not-allowed opacity-50"
                            : ""
                        }`}
                      >
                        <img src={right} alt="next" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <AnimatePresence>
            {noMoreUsers && (
              <motion.div
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.3 }}
                className="text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg"
              >
                <p>No more users to display.</p>
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {logicError && (
              <motion.div
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.3 }}
                className="text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg"
              >
                <p>{logicError}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ) : (
        <Layout>
          <div className="pt-9 pl-3 relative flex-1">
            <div className="mb-16">
              <h1 className="changa-bold text-xl mb-[2px] text-[#454488] user-card">
                Dashboard
              </h1>
              <p className="font-normal text-lg text-[#454488]">
                Find your dream co-founder
              </p>
            </div>
            <div className="pl-52">
              <div>
                {isLoading && (
                  <div className="3xl:pl-[170px]">
                    <SkeletonLoader />
                  </div>
                )}
                {userData && (
                  <div className="3xl:pl-[170px]">
                    {showCard ? null : (
                      <Card
                        fullName={userData.fullName}
                        userLocation={userData.userLocation}
                        skillDemanded={userData.skillDemanded}
                        elevatorPitch={userData.elevatorPitch}
                        profileImage={userData.profileImage}
                        rewindClick={handleRewindProfile}
                        onLikeClick={handleLikeClick}
                        onDislikeClick={handleDislikeClick}
                        onClickProfile={() => handleProfileClick(userData._id)}
                        modalData={modalData}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                      />
                    )}
                  </div>
                )}
                {!userData && (
                  <div className="3xl:pl-[170px]">
                    <OutOfUsers />
                  </div>
                )}
                {showCard && <CountdownTimer countdownTime={countdownTime} />}
              </div>
            </div>
          </div>
          <div className="flex-initial">
            <RightSidebar />
          </div>
          {/*  */}
          <AnimatePresence>
            {noMoreUsers && (
              <motion.div
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.3 }}
                className="text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg"
              >
                <p>No more users to display.</p>
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {logicError && (
              <motion.div
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.3 }}
                className="text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg"
              >
                <p>{logicError}</p>
              </motion.div>
            )}
          </AnimatePresence>

          {/*  */}
          {isRewindOpen && (
            <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
              {/* Center the modal */}
              <div className="bg-white w-auto h-auto rounded-lg px-16 py-7">
                <div className="modal-content">
                  {/*  */}
                  <div className="flex justify-end">
                    <button onClick={closeRewindModal}>
                      <img src={close} alt="close" />
                    </button>
                  </div>

                  <div className="flex flex-col justify-center items-center"></div>
                  <div className="flex justify-center items-center">
                    <img src={premium} alt="bod" />
                  </div>
                  <h1 className="text-center text-[#0F0F0F] text-3xl mb-2">
                    Upgrade to Premium
                  </h1>
                  <p className="text-[#5F5F5F] text-sm mb-10 text-center">
                    Upgrade your account to rewind
                  </p>
                  <div className="flex justify-center items-center">
                    <button className="px-20 py-2 text-white bg-[#414080] rounded-md">
                      Upgrade
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Layout>
      )}
    </div>
  );
};

export default DashboardLanding;

const Card = ({
  _id,
  fullName,
  userLocation,
  skillDemanded,
  elevatorPitch,
  rewindClick,
  profileImage,
  onLikeClick,
  onDislikeClick,
  onClickProfile,
  isModalOpen,
  setIsModalOpen,
  modalData,
}) => {
  const [showFullElevatorPitch, setShowFullElevatorPitch] = useState(false);
  const { user } = useUser();

  const handleToggleElevatorPitch = () => {
    setShowFullElevatorPitch(!showFullElevatorPitch);
  };

  const elevatorPitchToShow = showFullElevatorPitch
    ? elevatorPitch
    : elevatorPitch?.slice(0, 100) + "...";

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-white w-[340px] md:w-[431px] here rounded-lg px-8 pt-11 pb-11 mb-16 h-auto user-card">
      {/*  */}
      <div className="flex items-center gap-4 mb-5">
        <div>
          <img
            className="w-[66px] h-[66px] rounded-full"
            src={profileImage || avatar}
            alt="profile pic"
          />
        </div>
        <div className="">
          <div className="">
            <p className="changa-bold tracking-wide capitalize text-base text-[#0F0F0F]">
              {fullName}
            </p>
          </div>
          <div className="flex items-center gap-1">
            <div>
              <img src={locationicon} alt="location-icon" />
            </div>
            <div>
              <p className="capitalize">{userLocation}</p>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="flex items-center justify-center gap-2 mb-7 overflow-x-auto">
        {skillDemanded.map((skill, index) => (
          <div
            key={index}
            className="bg-[#FFF0FC] text-[#99007A] px-4 py-2 rounded-md capitalize text-sm w-[90px]"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {skill.length > 10 ? `${skill.slice(0, 10)}...` : skill}
          </div>
        ))}
      </div>
      {/*  */}
      <div>
        <div className="flex items-center gap-2 mb-6">
          <div>
            <img src={partner} alt="partner icon" />
          </div>
          <div>
            <p className="text-[13px]">
              I’m looking to partner with someone’s idea
            </p>
          </div>
        </div>
        {/*  */}
        <div className="mb-8">
          <h1 className="changa-bold text-[#0F0F0F] text-sm">Elevator Pitch</h1>
          <p className="">{elevatorPitchToShow}</p>
          {elevatorPitch?.length > 100 && (
            <button
              onClick={handleToggleElevatorPitch}
              className="text-gray-400 hover:underline"
            >
              {showFullElevatorPitch ? "Read Less" : "Read More"}
            </button>
          )}
        </div>
        {/*  */}
        <div className="flex justify-center items-center gap-8 action-buttons">
          <motion.div
            className="cursor-pointer rewind-button"
            onClick={rewindClick}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <img src={rewindd} alt="" />
          </motion.div>

          <motion.div
            className="cursor-pointer dislike-button"
            onClick={onDislikeClick}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <img src={dislike} alt="" />
          </motion.div>
          <motion.div
            className="cursor-pointer like-button"
            onClick={onLikeClick}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <img src={like} alt="" />
          </motion.div>
          <motion.div
            className="cursor-pointer profile-button"
            onClick={onClickProfile}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <img src={profile} alt="" />
          </motion.div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
          {/* Center the modal */}
          <div className="bg-white w-[431px] rounded-lg p-6 h-[391px] overflow-y-auto z-10">
            <div className="modal-content">
              {/*  */}
              <div className="flex justify-end">
                <button onClick={closeModal}>
                  <img src={close} alt="close" />
                </button>
              </div>
              {modalData ? (
                <div className="">
                  <div className="mb-5">
                    <img
                      className="w-[70px] h-[66px] rounded-full"
                      src={modalData.profileImage || avatar}
                      alt=""
                    />
                  </div>
                  <div>
                    <h2 className="font-bold mb-2 text-[#0F0F0F] text-xl changa-semibold">
                      {modalData.fullName}
                    </h2>
                    <div className="flex items-center gap-2 mb-5">
                      <div>
                        <img src={locationicon} alt="" />
                      </div>
                      <div>
                        <p className="text-xs">{modalData.userLocation}</p>
                      </div>
                    </div>
                    <div className="mb-5">
                      <h2 className="mb-2 text-[#0F0F0F] text-base changa-semibold">
                        Has skills
                      </h2>
                      <div className="flex gap-2">
                        {modalData.skillDemanded.map((skill, index) => (
                          <div
                            key={index}
                            className="px-4 py-2 bg-[#FFEBFB] rounded-lg flex items-center"
                          >
                            <p className="capitalize p-1 pl-2 text-sm text-[#99007A]">
                              {skill}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                    {/*  */}
                    <div className="mb-3">
                      <h2 className="text-[#0F0F0F] text-base changa-semibold mb-1">
                        Elevator Pitch
                      </h2>
                      <p className="text-base">{modalData.elevatorPitch}</p>
                    </div>
                    <div className="mb-3">
                      <h2 className="mb-2 text-[#0F0F0F] text-base font-bold">
                        Industry
                      </h2>
                      <div className="w-[150px] px-4 py-2 bg-[#FFEBFB] text-[#99007A] rounded-lg flex items-center">
                        <p className="capitalize p-1 pl-2 text-xs">
                          {modalData.industry}
                        </p>
                      </div>
                    </div>
                    {user.tier === "Free" ? (
                      <></>
                    ) : (
                      <div className="mb-3">
                        <h2 className="mb-2 text-[#0F0F0F] text-base font-bold">
                          Portfolio
                        </h2>
                        <a
                          href={modalData.portfolio}
                          target="_blank"
                          className=" text-xs text-orange-300"
                        >
                          visit portfolio
                        </a>
                      </div>
                    )}
                    {user.tier == "Free" ? (
                      <></>
                    ) : (
                      <div className="mb-3">
                        <h2 className="mb-2 text-[#0F0F0F] text-base font-bold">
                          Resume
                        </h2>
                        <a
                          href={modalData.resume}
                          target="_blank"
                          className=" text-xs text-orange-300"
                        >
                          see resume
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex justify-center items-center h-full">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Rewind Modal */}
    </div>
  );
};

const OutOfUsers = () => {
  return (
    <div className="here bg-white w-[340px] md:w-[431px] here rounded-lg px-8 pt-11 pb-11 mb-16 h-auto">
      <div className="flex justify-center items-center mb-3">
        <img src={oops} alt="" />
      </div>
      <div className="mb-3">
        <h1 className="text-center text-[#454488] text-2xl">
          Oops! You&apos;re out of matches 💔
        </h1>
      </div>
      <div>
        <p className="text-center text-base text-[#555555]">
          Looks like you&apos;ve swiped through everyone! No worries. Share
          HelpAI to your friends and keep conversations going.
        </p>
      </div>
    </div>
  );
};

const Content1 = ({ handleCompletion }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const { feedback, setFeedback } = useFeedback();

  const handleValueSelection = (value) => {
    setSelectedValue(value);
    handleCompletion(true);
  };

  useEffect(() => {
    if (selectedValue !== null) {
      setFeedback({ selectedValue });
    }
  }, [selectedValue, setFeedback]);

  // console.log({ feedback });

  return (
    <div>
      <h1 className="text-lg text-[#555555] mt-2 mb-9">
        On a scale of 1-10, how likely are you to recommend
        <br /> our company to a friend or colleague?
      </h1>
      <div className="flex justify-between items-center mb-2">
        {[...Array(10)].map((_, index) => (
          <div
            key={index}
            className={`border rounded-full h-[30px] w-[30px] flex justify-center items-center cursor-pointer hover:shadow-lg hover:scale-125 transition-all duration-300 ${
              selectedValue === index + 1 ? "bg-gray-400" : ""
            }`}
            onClick={() => handleValueSelection(index + 1)}
          >
            {index + 1}
          </div>
        ))}
      </div>
      <div className="flex justify-between items-center mb-5">
        <div>
          <p className="text-[#BE1919]">Not at all likely</p>
        </div>
        <div>
          <p className="text-[#18C975]">Extremely likely</p>
        </div>
      </div>
    </div>
  );
};

const Content2 = ({ handleCompletion }) => {
  const [reason, setReason] = useState();
  const { feedback, setFeedback } = useFeedback();

  useEffect(() => {
    handleCompletion(false);
  }, []);

  const handleChange = (e) => {
    setReason(e.target.value);
    setFeedback((prev) => ({ ...prev, reason }));
    handleCompletion(!!e.target.value.trim());
  };

  // console.log({ feedback });

  return (
    <div>
      <h1 className="text-lg text-[#555555] mt-2 mb-7">
        Please tell us why you give a rating
      </h1>
      <textarea
        name="reason"
        required
        onChange={handleChange}
        className="w-[100%] md:w-[418px] outline-none border border-gray-300 rounded-lg mb-9 p-1 h-[112px]"
      ></textarea>
    </div>
  );
};

const Content3 = ({ handleCompletion }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const { feedback, setFeedback } = useFeedback();

  useEffect(() => {
    handleCompletion(false);
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setFeedback((prev) => ({ ...prev, product: option }));
    handleCompletion(!!option);
  };

  // console.log({ feedback });

  return (
    <div>
      <h1 className="text-lg text-[#555555] mt-2 mb-6">
        How satisfied are you with the following experience with our company ?
      </h1>
      <div className="mb-4">
        <h1 className="changa-semibold text-xl mb-6">Product quality</h1>
        <div className="mb-5">
          <div
            className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
              selectedOption === "Extremely satisfied"
                ? "border border-[#454488]"
                : ""
            }`}
            onClick={() => handleOptionClick("Extremely satisfied")}
          >
            Extremely satisfied
          </div>
          <div
            className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
              selectedOption === "Moderately satisfied"
                ? "border border-[#454488]"
                : ""
            }`}
            onClick={() => handleOptionClick("Moderately satisfied")}
          >
            Moderately satisfied
          </div>
          <div
            className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
              selectedOption === "Neither satisfied nor dissatisfied"
                ? "border border-[#454488]"
                : ""
            }`}
            onClick={() =>
              handleOptionClick("Neither satisfied nor dissatisfied")
            }
          >
            Neither satisfied nor dissatisfied
          </div>
          <div
            className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
              selectedOption === "Moderately dissatisfied"
                ? "border border-[#454488]"
                : ""
            }`}
            onClick={() => handleOptionClick("Moderately dissatisfied")}
          >
            Moderately dissatisfied
          </div>
          <div
            className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
              selectedOption === "Extremely dissatisfied"
                ? "border border-[#454488]"
                : ""
            }`}
            onClick={() => handleOptionClick("Extremely dissatisfied")}
          >
            Extremely dissatisfied
          </div>
        </div>
      </div>
    </div>
  );
};

const Content4 = ({ handleCompletion }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const { feedback, setFeedback } = useFeedback();

  useEffect(() => {
    handleCompletion(false);
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setFeedback((prev) => ({ ...prev, customer: option }));
    handleCompletion(!!option);
  };

  // console.log({ feedback });

  return (
    <div>
      <h1 className="text-lg text-[#555555] mt-2 mb-6">
        How satisfied are you with the following experience
        <br /> with our company ?
      </h1>
      <div>
        <h1 className="changa-semibold text-xl mb-6">Customer support</h1>
        <div className="mb-5">
          <div
            className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
              selectedOption === "Extremely satisfied"
                ? "border border-[#454488]"
                : ""
            }`}
            onClick={() => handleOptionClick("Extremely satisfied")}
          >
            Extremely satisfied
          </div>
          <div
            className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
              selectedOption === "Moderately satisfied"
                ? "border border-[#454488]"
                : ""
            }`}
            onClick={() => handleOptionClick("Moderately satisfied")}
          >
            Moderately satisfied
          </div>
          <div
            className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
              selectedOption === "Neither satisfied nor dissatisfied"
                ? "border border-[#454488]"
                : ""
            }`}
            onClick={() =>
              handleOptionClick("Neither satisfied nor dissatisfied")
            }
          >
            Neither satisfied nor dissatisfied
          </div>
          <div
            className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
              selectedOption === "Moderately dissatisfied"
                ? "border border-[#454488]"
                : ""
            }`}
            onClick={() => handleOptionClick("Moderately dissatisfied")}
          >
            Moderately dissatisfied
          </div>
          <div
            className={`bg-[#F4F4F4] px-4 py-4 mb-2 rounded-lg cursor-pointer ${
              selectedOption === "Extremely dissatisfied"
                ? "border border-[#454488]"
                : ""
            }`}
            onClick={() => handleOptionClick("Extremely dissatisfied")}
          >
            Extremely dissatisfied
          </div>
        </div>
      </div>
    </div>
  );
};

const Content5 = ({ handleCompletion }) => {
  const [advise, setAdvise] = useState();
  const { feedback, setFeedback } = useFeedback();

  useEffect(() => {
    handleCompletion(false);
  }, []);

  const handleChange = (e) => {
    setAdvise(e.target.value);
    setFeedback((prev) => ({ ...prev, advise }));
    handleCompletion(!!e.target.value.trim());
  };

  // console.log({ feedback });

  return (
    <div>
      <h1 className="text-lg text-[#555555] mt-2 mb-7">
        Is there anything specific that our company can do to improve your
        experience?
      </h1>
      <textarea
        name="advise"
        required
        onChange={handleChange}
        className="w-[100%] md:w-[418px] outline-none border border-gray-300 rounded-lg mb-9 p-1 h-[112px]"
      ></textarea>
    </div>
  );
};

const Content6 = ({ closeModal }) => (
  <div>
    <div className="flex justify-center items-center">
      <div>
        <div className="flex justify-center items-center mb-7">
          <img className="" src={successTick} alt="Success Tick" />
        </div>
        <div className="text-center">
          <h2 className="mb-1 text-xl leading-[35px] changa-bold text-[#454488]">
            Thanks for your feedback!
          </h2>
          <p className=" text-[#373737] text-base">
            We appreciate your contribution to our
            <br /> app's enhancement
          </p>
        </div>
        <div className="mt-10 mb-10">
          <button
            onClick={closeModal}
            className="w-full h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px]"
          >
            Return to Dashboard
          </button>
        </div>
      </div>
    </div>
  </div>
);
